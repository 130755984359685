<template>
<div>
    <b-card title="" header-tag="header">
        <template v-slot:header>
            <div class="card-title m-0">
                <h3 class="card-label m-0">{{ title }}</h3>
            </div>
            <div class="card-toolbar">

            </div>
        </template>

        <form class="form" novalidate="novalidate" id="product_basic">
            <b-form-group label-for="input-1">
                <label>Attribute Group Name
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.name" type="text" name="name" class="form-control" placeholder="EG: Shape"></b-form-input>
            </b-form-group>

            <b-form-group label-for="input-1">
                <label>Sort Order
                    <sup class="text-danger font-weight-boldest">*</sup></label>
                <b-form-input v-model="form.sort_order" type="number" name="sort" class="form-control"></b-form-input>
            </b-form-group>

            <b-row>
                <b-col class="text-right">
                    <button ref="kt_login_signin_submit" class="btn btn-sm btn-outline-primary font-size-h6 ">
                       <i class="las la-save"></i> Save
                    </button>
                </b-col>
            </b-row>
        </form>
    </b-card>
</div>
</template>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Swal from "sweetalert2";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
//api services
import AttributeService from "@/core/services/api/attribute";
export default {
    components: {},
    data() {
        return {
            title: "New Attribute Group",
            id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
            form: {
                id: null,
                name: "",
                sort_order: 0,
            },
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
                title: "Dashboard",
                route: "/dashboard",
            },
            {
                title: "Attribute Group",
                route: "/attribute-group",
            },
            {
                title: "Attribute Group : New",
            },
        ]);
        const signin_form = KTUtil.getById("product_basic");
        this.fv = formValidation(signin_form, {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: "Name is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });
        this.fv.on("core.form.valid", () => {

            // set spinner to submit button
            const submitButton = this.$refs["kt_login_signin_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
            var vm = this;
            if(this.id == null){
                this.createAttribute(function(response){
                    vm.response(response,submitButton);
                });
            }else{
                this.updateAttribute(function(response){
                    vm.response(response,submitButton);
                });
            }
               
        });
        if(this.id != null){
            this.getAttributeGroupById();
        }
    },
    methods: {
        response(response,submitButton){
            var vm = this;
            if (response) {
                let timerInterval;
                Swal.fire({
                    title: response.message,
                    timer: 500,
                    icon: "success",
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector("b");
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                    vm.$router.push({ name: "attribute-group" })
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {

                    }
                });
            }
            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right",
                "disabled"
            );
        },
        async getAttributeGroupById() {
            var response = await AttributeService.getAttributeGroupById(this.id);
            this.form = response;
        },
        createAttribute(callback) {
            AttributeService.createAttributeGroup(this.form).then(function (response) {
               callback(response);
            });
        },
        updateAttribute(callback) {
            AttributeService.updateAttributeGroup(this.form).then(function (response) {
               callback(response);
            });
        },
    },
};
</script>

<style></style>
